module.exports = {
	defaultTitle: 'Saad Ahmed',
	author: 'Saad Ahmed',
	url: 'https://www.randinterval.com',
	legalName: 'Saad Ahmed',
	defaultDescription: 'Software Engineer with a passion for understanding things at a fundamental level.',
	socialLinks: {
		twitter: 'http://www.twitter.com/randinterval',
		github: 'https://github.com/randinterval'
	},
	themeColor: '#6b63ff',
	backgroundColor: '#6b63ff',
	social: {
		twitter: '@randinterval',
	},
	address: {
		city: ':Lahore',
		region: 'Punjab',
		country: 'Pakistan',
		zipCode: '54000',
	},
	contact: {
		email: 'saad@randinterval.com'
	},
	foundingDate: '2018',
	recaptcha_key: '6LeuYcEUAAAAALVhvsF4cfnf112b2Ob25sY5w_kC'
}